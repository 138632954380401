// import React, { useEffect, useState, useMemo } from 'react';
// import PropTypes from 'prop-types';
// import { useFormContext, Controller } from 'react-hook-form';
// import { Autocomplete, TextField, CircularProgress } from '@mui/material';
// import { getCitiesApi } from '../../apis/City/CityApis';

// RHFMultiSelectOptions.propTypes = {
//     name: PropTypes.string.isRequired,
//     label: PropTypes.string.isRequired,
//     defaultValue: PropTypes.array,
// };

// export default function RHFMultiSelectOptions({ name, label, defaultValue = [], ...other }) {
//     const {
//         control,
//         formState: { errors },
//     } = useFormContext();
//     const [options, setOptions] = useState([]);
//     const [isLoading, setIsLoading] = useState(false);
//     const [activeData, setActiveData] = useState({ page: 1, limit: 500, search: '' });
//     const [hasMore, setHasMore] = useState(true);

//     const fetchCities = async (data) => {
//         setIsLoading(true);
//         try {
//             const res = await getCitiesApi(data);
//             const newOptions =
//                 res?.data?.data?.rows?.map((city) => ({
//                     id: city.id,
//                     label: city.name,
//                 })) || [];

//             if (newOptions.length < data.limit) {
//                 setHasMore(false);
//             }

//             setOptions((prev) => (data.page === 1 ? newOptions : [...prev, ...newOptions]));
//         } catch (err) {
//             console.error(err);
//         }
//         setIsLoading(false);
//     };

//     useEffect(() => {
//         fetchCities(activeData);
//     }, [activeData]);

//     const handleSearch = (search) => {
//         setActiveData({ page: 1, limit: 50000, search });
//         setHasMore(false); // Disable pagination on search
//     };

//     const handleLoadMore = () => {
//         if (hasMore && !isLoading) {
//             setActiveData((prev) => ({ ...prev, page: prev.page + 1 }));
//         }
//     };

//     const processedOptions = useMemo(() => options || [], [options]);

//     return (
//         <Controller
//             name={name}
//             control={control}
//             defaultValue={defaultValue}
//             render={({ field: { onChange, value = [] } }) => (
//                 <Autocomplete
//                     multiple
//                     options={processedOptions}
//                     value={processedOptions.filter((option) => value.includes(option.id))}
//                     getOptionLabel={(option) => option.label}
//                     isOptionEqualToValue={(option, value) => option.id === value.id}
//                     onInputChange={(event, newInputValue) => {
//                         if (newInputValue.length > 2) {
//                             handleSearch(newInputValue);
//                         }
//                     }}
//                     onChange={(event, newValue) => {
//                         const selectedValues = newValue.map((item) => item.id);
//                         onChange(selectedValues);
//                     }}
//                     renderInput={(params) => (
//                         <TextField
//                             {...params}
//                             label={label}
//                             error={!!errors[name]}
//                             helperText={errors[name]?.message || ''}
//                             InputProps={{
//                                 ...params.InputProps,
//                                 endAdornment: (
//                                     <>
//                                         {isLoading && <CircularProgress color="inherit" size={20} />}
//                                         {params.InputProps.endAdornment}
//                                     </>
//                                 ),
//                             }}
//                         />
//                     )}
//                     ListboxProps={{
//                         onScroll: (event) => {
//                             const listboxNode = event.currentTarget;
//                             if (listboxNode.scrollTop + listboxNode.clientHeight >= listboxNode.scrollHeight - 10) {
//                                 handleLoadMore();
//                             }
//                         },
//                     }}
//                 />
//             )}
//         />
//     );
// }

import React, { useEffect, useState } from 'react';
import AsyncSelect from 'react-select';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/material';
import { getCitiesApi } from '../../apis/City/CityApis';

const StyledAsyncSelect = styled(AsyncSelect)(() => ({
    '& .MuiInputLabel-asterisk': {
        color: 'red',
    },
}));

CitySelectMulti.propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.any,
    isError: PropTypes.bool,
};

/* eslint-disable */

function CitySelectMulti({ onChange, value, isError, ...other }) {
    const theme = useTheme();
    const [options, setOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [activeData, setActiveData] = useState({
        page: 1,
        limit: 100,
        search: '',
    });

    const getCities = (data) => {
        setIsLoading(true);
        getCitiesApi(data)
            .then((res) => {
                const options = res?.data?.data?.rows?.map((item) => ({
                    value: item?.id,
                    label: item?.name,
                }));
                if (activeData.page === 1) {
                    setOptions(options);
                } else {
                    setOptions((prev) => [...prev, ...options]);
                }
                setIsLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    };

    const handleSearch = (search) => {
        setActiveData((prev) => ({ ...prev, search: search }));
    };

    const handleLoadMore = () => {
        setActiveData((prev) => ({ ...prev, page: activeData.page + 1 }));
    };

    useEffect(() => {
        getCities(activeData);
    }, [activeData]);

    return (
        <AsyncSelect
            cacheOptions
            isMulti
            onInputChange={(inputValue) => handleSearch(inputValue)}
            defaultOptions={options}
            options={options}
            onChange={onChange}
            value={value}
            isLoading={isLoading}
            isClearable
            isSearchable
            onMenuScrollToBottom={handleLoadMore}
            menuPortalTarget={document.body}
            menuPosition="fixed"
            styles={{
                control: (base, state) => ({
                    ...base,
                    backgroundColor: 'white',
                    borderColor: isError ? 'red' : state.isFocused ? theme.palette.primary.main : base.borderColor,
                    '&:hover': {
                        borderColor: isError ? 'red' : state.isFocused ? theme.palette.primary.main : base.borderColor,
                    },
                    boxShadow: 'none',
                    minHeight: '3.3rem',
                }),
                indicatorSeparator: () => ({
                    display: 'none',
                }),
                multiValue: (base) => ({
                    ...base,
                    backgroundColor: theme.palette.grey[200],
                }),
                multiValueLabel: (base) => ({
                    ...base,
                    color: theme.palette.black,
                }),
                multiValueRemove: (base) => ({
                    ...base,
                    color: theme.palette.black,
                    ':hover': {
                        backgroundColor: theme.palette.grey[300],
                        color: 'black',
                    },
                }),
                menuPortal: (base) => ({
                    ...base,
                    zIndex: 9999,
                }),
            }}
            placeholder="Select Cities"
            {...other}
        />
    );
}

export default CitySelectMulti;
