/* eslint-disable arrow-body-style */
import { postMethod } from "../apiConfig";

export const getCitiesApi = (data) => {
    return postMethod('/city/get-cities', data);
};

export const getCitiesByStateApi = (data) => {
    return postMethod('/city/get-cities-by-state', data);
};

export const getCityApi = (data) => {
    return postMethod('/city/get-city', data);
}

export const addCityApi = (data) => {
    return postMethod('/city/add-city', data);
}

export const updateCityApi = (data) => {
    return postMethod('/city/update-city', data);
}

export const deleteCityApi = (data) => {
    return postMethod('/city/delete-city', data);
}

/* eslint-disable arrow-body-style */
